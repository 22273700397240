@media print {
    /* a[href*='//']:after {content:" (" attr(href) ") ";color: red;}
    a:after {content:" (" attr(href) ") "; color: green;} */
    p, .glosssry-desc {page-break-inside: avoid;}

    * {background-attachment: unset !important;}

    @page {margin-top: 0cm; margin-bottom: 0cm; margin-left: 0cm; margin-right: 0cm;}
    body.home { background-size: 100%!important; }
    body { margin: 0 !important; background-size: 100%!important; background-attachment: unset !important; }

    .main-header.is-sticky-header {  position: absolute !important; }

    .main-footer { padding: 25px 0 !important; }
    .main-footer .row .col-xl-7 { max-width: 60% !important; float: left !important; }
    .main-footer .row .col-xl-6 { max-width: 50% !important; float: left !important; }
    .main-footer .row .col-xl-5 { max-width: 40% !important; float: left !important; }
    .main-footer .row .col-xl-5 ul.menu { max-width: 100% !important; float: right !important; }
    .main-footer .row .col-xl-6 ul.menu { max-width: 100% !important; float: right !important; }

    body.home .main-content-wrapper.bg-cover {height: calc(100vh - 100px);}
    .bg-home { background-size: 100% 100% !important; background-attachment: unset !important; height: calc(100vh - 100px); }
    .main-content-wrapper.bg-tab-wrapper, .main-content-wrapper.bg-wrapper { background-size: 100% !important; background-attachment: unset !important; }
    .main-content-wrapper.bg-cover { background-size: 100%!important; }
    .main-content-wrapper::after { position: absolute !important; }
    .main-content-wrapper { background-size: 100% !important; background-attachment: unset !important; }

    .main-content-wrapper { display: block !important; }
    .home-welcome { max-width: 500px !important ; margin-left: auto !important; padding-top: 27px !important; }
    .home-grid-actions { padding: 30px 0 25px!important; }
    .home-welcome h1 { margin-bottom: 15px !important; }

    .cell-welcome { padding-top: 40px !important; max-width: 500px !important; margin-left: auto !important; }
    .cell-welcome h1 { margin-bottom: 18px !important; }
    .page-grid-actions { padding: 0px 0 20px!important; }
    body.home .main-content-wrapper { min-height: auto; }
    .home-grid-actions .type-links li { margin: 18px 12px !important; min-width: calc(20% - 24px) !important; }

    .title-box { padding-top: 50px !important; }
    .collapse-card .card-header { border-radius: 8px 8px 0 0 !important; }
    .collapse-card .card-header .collapse-icon::after {background: transparent;}
    .collapse-card .card-body .content-block { padding: 30px 30px 20px !important; }
    .collapsiable-card-block { padding-top: 40px !important; }
    .collapse-card .card-body { height: auto !important; }
    .collapse-card .card-body .inner-content .col-lg-2 { max-width: 24% !important; float: left !important; }
    .collapse-card .card-body .inner-content .col-lg-5 { max-width: 38% !important; float: left !important; }
    .table-content-block .table-row { grid-template-columns: 240px 150px 260px 380px !important; padding: 18px 0px !important; }
    .table-content-block { overflow: hidden !important; overflow-x: hidden !important; }

    .filter-link .filter-btn { padding: 10px 50px !important; }
    .filter-content-block .sorting-list .sort-link { padding: 8px 8px 12px !important; }

    .page-grid-actions .type-links li { min-width: calc(20% - 20px); margin: 40px 10px; }
    .page-grid-actions { padding: 10px 0 40px !important; }
    .type-innerpage-title .back-action { margin-bottom: 10px; margin-top: 15px; }

    .type-innerpage-title { max-width: 500px !important; }
    .type-sub-link-block .type-sub-links li a, .type-sub-link-block .type-sub-links li .target-link { padding: 15px 0 15px 25px !important; }
    .type-sub-link-block .type-sub-links li, .type-sub-link-block .type-sub-links li { margin: 12px 15px !important; }

    .type-sub-card { margin: 0px -20px !important; grid-template-columns: repeat(3, 33.33%) !important; }
    .type-innerpage-title h1 { margin-bottom: 5px; }
    .type-innerpage-title hr { margin: 10px 0; }
    .type-action-card { padding: 15px 0 30px !important; }
    .type-action-card.type-action-card-pr { padding-right: 50px; }
    .type-sub-card > li { margin: 0px 15px 20px !important; }
    .type-sub-card .card-block .card-body ul.list-info li { margin-bottom: 20px !important; font-size: 18px !important; }
    .tab-content-block .tab-content .tab-content-body br { display: none !important; }

    .abbreviations-data-lists { grid-template-columns: repeat(3, 2fr) !important; grid-gap: 14px !important; }
    .type-innerpage-title-row { grid-template-columns: 12% 280px 500px auto !important; }
    .main-content-wrapper.bg-tab-wrapper::after { bottom: 0 !important; }

    .tab-content-popup { padding: 30px 15px 30px 30px !important; }
    .tab-content-popup .popup-header { margin-bottom: 0px !important; }
    .tab-content-popup .popup-header h2 { margin-bottom: 0px !important; }
    .tab-content-block .tab-content .type-sub-links li { margin: 0px 15px 25px !important; }

    .tab-content-block .tab-content .action-link a { display: block !important; }
    .type-sub-card > li:nth-last-child(-n+3) { margin-bottom: 0px !important; }
    .abbreviations-block { margin-bottom: 0 !important; }
    .abbreviations-data-lists li:nth-last-child(-n+3) p { margin-bottom: 0 !important; }

    .more-info-block .more-info-content .more-info-grid { grid-template-columns: repeat(2, 60% 40%) !important; }
    .more-info-block .more-info-content .step-overview-desc strong { display: block !important; }

    .ddr-tabs { display: none !important; }
    .ddr-tab-content-block { margin-bottom: 0 !important;  width: 100%;}
    .ddr-tab-content-block .tab-content { display: flex !important; flex-direction: column !important; align-items: flex-start !important; }
    .ddr-tab-content-block .tab-header { display: inline-flex !important; align-items: center !important; justify-content: space-between; width: 100%; color: rgba(255, 255, 255, 1); background: #3b495b; padding: 20px 30px; margin-bottom: 1px; border-radius: 26px 26px 0px 0px; }
    .ddr-tab-content-block .tab-content .tab-content-body { width: 100%; }
    .ddr-tab-content-block .tab-header h2 { margin: 0; }
    .ddr-tab-content-block .tab-header .az-icon {-moz-opacity: 1; -khtml-opacity: 1; -webkit-opacity: 1; opacity: 1; -moz-transform: rotate(90deg); -webkit-transform: rotate(90deg); -o-transform: rotate(90deg); -ms-transform: rotate(90deg); transform: rotate(90deg);    }
    .ddr-overview-page-title { max-width: 70% !important; }
    .type-innerpage-title.ddr-overview-page-title h1 { margin-bottom: 10px !important; }
    .type-innerpage-title.ddr-overview-page-title .desc { min-height: auto !important; }
    .ddr-tab-content-block .tab-content .image-card .card-body { max-height: inherit !important; width: 100%; overflow: unset !important; }
    .image-card .card-body .image-map-block .panzoom-element { display: flex !important; flex-direction: column !important; align-items: flex-start !important;  overflow: unset !important;}
    .ddr-tab-content-block .tab-content .image-card-block { padding: 0px 0 60px !important; }
    .image-card .card-body .image-map-block img.img-panzoom {width: 100%; max-width: 100%;}
    .server-wrapper { padding-top: 80px !important; }
    .server-error-block { margin-top: 0 !important; }
    .server-error-block .server-error-card { margin: 60px 0 !important; padding: 80px 40px !important; }

    .custom-tabs { display: none !important; }
    .tab-content-block .tab-content { display: block !important; padding-bottom: 30px !important; }
    .tab-content-block .tab-content .tab-header { display: inline-flex !important; align-items:center !important; justify-content: space-between !important; width: 100%; color: rgba(255, 255, 255, 0.8); background: #0D151F; padding: 20px 30px; margin-bottom: 1px; }
    .tab-content-block .tab-content .tab-header h2{ margin: 0; }
    .tab-content-block .tab-content .tab-header .az-icon {-moz-opacity: 1; -khtml-opacity: 1; -webkit-opacity: 1; opacity: 1; -moz-transform: rotate(90deg); -webkit-transform: rotate(90deg); -o-transform: rotate(90deg); -ms-transform: rotate(90deg); transform: rotate(90deg);  }
    .tab-content-block { padding: 0 !important; }
    .tab-content-block .tab-content .tab-content-body { padding-top: 30px !important; }
    .tab-content-block .tab-content .tab-type-action-card { padding: 0px 0 30px; }

    .custom-modal {position: relative !important; padding-top: 100px; padding-bottom: 100px; height: auto; z-index: 99; visibility: visible; opacity: 1; page-break-inside: avoid; page-break-after:always; page-break-before:always}
    .custom-modal .wrapper {min-height: auto; }
    .custom-modal .wrapper .content-footer .btn { max-width: 450px !important; }
    .custom-modal .wrapper .content-footer .btn:first-child { float: right !important; }
}
